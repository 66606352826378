.mobile-mode {
  .react-table {
    max-height: calc(100dvh - 260px) !important;
  }

  .list-tree {
    .react-table {
      max-height: none !important;
    }
  }

  .data-grid {
    thead {
      th {
        input,
        select,
        .form-control {
          font-size: 16px;
        }

        .lucide {
          height: 24px !important;
          width: 24px !important;
          margin: 0 6px;
        }

        &.sticky-header-column {
          .btn {
            font-size: 16px;
          }
        }
      }
    }

    tbody {
      td {
        font-size: 14px;
      }

      .btn-group {
        .btn-sm {
          height: 45px;
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;

          i.mdi {
            font-size: 18px;
          }
        }
      }
    }
  }

  .data-grid-footer-pagination {
    padding: 12px 16px !important;

    button {
      font-size: 18px;
    }

    .pages-information {
      font-size: 18px;
    }
  }

  .api-form {
    .form-sticky-header {
      .btn {
        font-size: 16px;
      }

      h4 {
        font-size: 20px;
      }
    }
  }
}

.list-selector-modal.mobile-mode {
  .table-picker {
    td {
      button {
        font-size: 18px !important;
      }

      input[type='checkbox'] {
        height: 20px;
        width: 20px;
        display: block;
        margin: auto;
      }
    }
  }
}
