.store-active {
  &__wrapper {
    padding: 4px;
    color: white;
    background-color: rgb(216, 39, 39);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    &--active {
      background-color: rgb(67, 200, 30);
    }
  }
}
