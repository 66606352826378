body {
  --keyboard-zindex: 9999;

  .ML__keyboard {
    --keyboard-background: #2a3041;
    --keycap-modifier-background: #fff;

    .ML__keyboard--plate {
      .keyboard-toolbar {
        .right {
          --keyboard-text: #fff;
        }

        .left {
          --keyboard-text: #ccc;
          --keyboard-text-active: #fff;
        }
      }

      div .rows {
        .modifier {
          --keycap-text: #303030;
        }

        .calculator-field-btn {
          --keycap-font-size: 13px;
          --bs-body-line-height: 16px;

          .ML__mathlive,
          .ML__base {
            width: 100%;
            white-space: initial;
            text-align: center;
          }

          .ML__text {
            white-space: initial;
          }
        }

        > ul > li.modifier {
          font-weight: 400;
        }
      }
    }
  }
}

.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.margin-none {
  margin: initial !important;
}

@for $i from 1 through 64 {
  .fs-#{$i} {
    font-size: $i + px;
  }
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

body .react-toast-notifications__container {
  z-index: 9999;
}

.img-form {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.dropzone {
  min-height: initial;
  height: 100%;
  position: relative;

  .dropzone-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    i.bxs-cloud-upload {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.dropzone-image {
  .dz-message {
    padding: 0;
  }

  &.dropzone-filled {
    &.auto-height {
      min-height: initial;
      height: 100%;
    }

    position: relative;

    .dz-message {
      opacity: 0;
    }

    &:hover {
      .dz-message {
        opacity: 1;

        &::before {
          content: '';
          background: rgba(255, 255, 255, 0.7);
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }

    .dropzone-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      z-index: 10;
    }
  }
}

.bg-dark-accent {
  background: $sidebar-dark-bg;
}

.dark-accent-color {
  color: $sidebar-dark-menu-item-color !important;
}

.border-small {
  border: 1px solid #ced4da;
}

.border-top-none {
  border-top: none;
}

.border-radius-top-left-none {
  border-top-left-radius: 0;
}

.border-radius-top {
  @include border-top-radius($nav-tabs-border-radius);
}

.logo {
  line-height: $header-height;
}

.page-content {
  min-height: 100vh;
}

.ctm-form-check {
  .form-check-label {
    cursor: pointer;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    top: 0;
    padding-left: $form-switch-padding-start;
  }

  &.form-check {
    padding-left: 0;
  }

  &.switch-on-right {
    .form-check-label {
      position: initial;
      left: initial;
      top: initial;
      padding-left: initial;
      padding-right: $form-switch-padding-start;
    }

    &.form-check .form-check-input {
      margin-left: 0.1em;
      padding-left: $form-switch-padding-start;
      position: initial;
      left: initial;
      top: initial;
      float: initial;
    }

    &.form-check .form-switch {
      padding-left: initial;
    }
  }

  &.checked .form-check-input {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;
    background-position: $form-switch-checked-bg-position;
    background-image: escape-svg($form-switch-checked-bg-image), var(--#{$variable-prefix}gradient);
  }

  &.not-checked .form-check-input {
    background-color: $form-check-input-bg;
    background-image: escape-svg($form-switch-bg-image);
    background-position: left center;
  }
}

.data-grid {
  td {
    vertical-align: middle;
  }

  &.table-clickable {
    tr {
      cursor: pointer;

      &:hover {
        background: $table-hover-bg;
      }
    }
  }

  .ctm-select2 {
    min-width: 80px;
  }

  .form-control {
    min-width: 60px;
  }
}

.cf-field {
  margin-bottom: 6px;

  .cf-field-container {
    //padding: 6px;
    //border: 1px solid #eceffb;
    //border-radius: 5px;
    //margin: 6px;
  }

  //.cf-field-btn {
  //  padding: 8px;
  //  border-radius: 5px;
  //  border: 1px solid #dedede;
  //  font-size: var(--bs-body-font-size);
  //  margin-left: 8px;
  //  &.mdi-delete {
  //    color: $red-400;
  //  }
  //}
}

.disable-group-margin.mb-3 {
  margin: initial !important;
}

.accordion-button-ctm {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.7rem 0 0;
  @include font-size($font-size-base);
  color: $accordion-button-color;
  text-align: left; // Reset button style
  background-color: $accordion-button-bg;
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.not-icon) {
    &:not(.collapsed) {
      color: $accordion-button-active-color;
      background-color: $accordion-button-active-bg;
      box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;

      &:not(.just-button) {
        &::after {
          background-image: escape-svg($accordion-button-active-icon);
          transform: $accordion-icon-transform;
        }
      }
    }
  }

  &:hover {
    z-index: 2;
  }

  &:not(.just-button) {
    &::after {
      flex-shrink: 0;
      width: $accordion-icon-width;
      height: $accordion-icon-width;
      margin-left: 8px;
      content: '';
      background-image: escape-svg($accordion-button-icon);
      background-repeat: no-repeat;
      background-size: $accordion-icon-width;
      @include transition($accordion-icon-transition);
    }
  }

  &:focus {
    z-index: 3;
    border-color: $accordion-button-focus-border-color;
    outline: 0;
    box-shadow: $accordion-button-focus-box-shadow;
  }
}

.select2-selection__menu {
  z-index: 9999 !important;
  color: #484f56 !important;
}

.modal-size-form {
  max-width: calc(100vw - 250px);
}

.select2-selection__placeholder {
  color: #495057 !important;
  font-weight: 100;
}

.product-production-configuration {
  .accordion-body {
    padding: 0px 1px 1px 40px;
  }
}

.tree-icon-grid {
  position: relative;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  width: 70px;
  //  height: 50px;
  //  border-bottom: 1px solid #495057;
  //  border-left: 1px solid #495057;
  //  left: 8px;
  //  bottom: -43px;
  //}
}

.list-tree {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 3px);
    border-bottom: 1px solid #495057;
    border-left: 1px solid #495057;
    left: -6px;
    top: -20px;
  }

  table tr > td[role='cell']:first-child > div:not(.w-100) {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 1px;
      border-bottom: 1px solid #495057;
      border-left: 1px solid #495057;
      left: -22px;
      top: 12px;
    }
  }
}

.tree,
.tree .tree-child {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tree {
  .tree {
    margin-left: 0.4em;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 1px solid;
    }

    .tree-child {
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 0;
        border-top: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: 1em;
        left: 0;
      }

      &:last-child:before {
        background: #fff;
        height: auto;
        top: 1em;
        bottom: 0;
      }
    }

    .tree {
      margin-left: 0.3em;
    }
  }

  .tree-child {
    margin: 0;
    padding: 0 0 0 0.8em;
    line-height: 2em;
    color: #555;
    font-weight: 700;
    position: relative;
  }
}

.form-control[type='color'] {
  height: 35px;
}

.form-control.hide-arrows,
input.hide-arrows {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -moz-appearance: textfield;
}

.product-builder-graph {
  width: 100%;
  height: calc(100dvh - 250px);
  margin-bottom: -70px;

  .node-technology {
    border: 1px solid black;
    background-color: #fff;
    position: relative;
    transform: skewX(-45deg);
    max-width: 180px;
    margin: 0 auto;

    .node-wrapper {
      padding: 0px 15px;
      border-radius: 0px;
      transform: skewX(+45deg);
    }

    .form-control {
      border: none;
    }

    .add-product {
      position: absolute;
      left: calc(50%);
      transform: translateX(-50%);
      top: calc(100% + 22px);
    }
  }

  .node-product {
    border: 1px solid black;
    background-color: #fff;
    position: relative;
    border-radius: 10px;
    margin: 15px auto 0 auto;
    max-width: 250px;
    padding-top: 15px;
    top: 30%;
    transform: translateY(-50%);

    .container {
      width: 100%;
      padding: 15px;
    }

    i.product-icon {
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      background: #fff;
      padding: 4px 10px;
      border: 1px solid #303030;
      border-radius: 100%;
      color: #fff;
    }

    .actions {
      width: 100%;
      align-content: space-between;
      padding: 6px 10px;

      button {
        margin-left: 6px;

        &:first-child {
          margin-left: 0;
        }

        border-radius: 100%;
      }
    }

    .form-control {
      border: none;
      background: transparent !important;
    }

    .configure-product {
      position: absolute;
      display: none;
      right: -19px;
      top: 10px;
      height: 38px;
      border-radius: 100%;
    }

    .remove-product {
      position: absolute;
      display: none;
      right: -19px;
      bottom: 10px;
      height: 38px;
      border-radius: 100%;
    }

    &:hover {
      .configure-product,
      .remove-product {
        display: block;
      }
    }

    .technology-block {
      border: 1px solid black;
      background-color: #fff;
      transform: skewX(-45deg);
      position: absolute;
      bottom: -45px;

      .technology-content {
        padding: 0 15px;
        border-radius: 0px;
        transform: skewX(+45deg);

        .form-control {
          text-align: center;
        }
      }

      .add-product {
        position: absolute;
        left: 50%;
        bottom: -45px;
        transform: translateX(-50%);
        z-index: 10;

        &::before {
          content: '';
          height: 10px;
          width: 1px;
          border-left: 1px solid #303030;
          background: #303030;
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .toggle-node {
        position: absolute;
        left: 50%;
        bottom: -105px;
        transform: translateX(-50%);

        &::before {
          content: '';
          height: 68px;
          width: 1px;
          border-left: 1px solid #303030;
          background: #303030;
          position: absolute;
          top: -68px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &::before {
        content: '';
        height: 10px;
        width: 1px;
        border-left: 1px solid #303030;
        background: #303030;
        position: absolute;
        top: -11px;
        left: 41%;
        transform: translateX(-50%) skewX(45deg);
      }
    }
  }
}

.node-product-block {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 25px;

  .technology-node {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    &::before {
      content: '';
      height: 0;
      width: 1px;
      background: #000;
      position: absolute;
      top: -88px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .toggle-node {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);

    &::before {
      content: '';
      height: 0;
      width: 1px;
      background: #000;
      position: absolute;
      top: -88px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .add-node {
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    padding: 3px 8px;

    &::before {
      content: '';
      width: 1px;
      background: #000;
      position: absolute;
      height: 0;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    &.is-root {
      &:before {
        height: 0px;
      }
    }
  }
}

.document_type-card {
  padding: 30px;
  transition: all 0.7s ease-in;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-weight: 400;
  text-align: center;

  &.document_type-card-small {
    min-width: initial;
    max-width: initial;
    padding: 8px;

    &.active,
    &:hover {
      background: $primary;
      color: $white;
    }
  }

  .icon {
    text-align: center;
    padding: 6px;
    font-weight: 600;
    font-size: 16px;
    background: $primary;
    color: $white;
  }

  &:hover {
    box-shadow: 6px 6px 20px 0px #969696;
  }
}

.list-selector-modal {
  @media (max-width: 790px) {
    .modal-content {
      .modal-body {
        .react-table {
          max-height: 87vh;
        }
      }
    }
  }
  .modal-header-nav-bar {
    &.collapsed {
      position: fixed;
      bottom: -1px;
      max-width: 300px;
      left: 250px;
    }
  }
  @media (max-width: 620px) {
    max-width: 100vw !important;
    max-height: 100vh !important;
    margin: initial !important;

    .modal-content {
      .modal-body {
        padding: initial !important;
        height: 100vh;
        border-radius: 0;

        .modal-header-nav-bar {
          border-radius: 0;
        }

        .react-table {
          max-height: calc(100vh - 200px);
        }
      }
    }
  }

  .modal-body {
    padding: 0 0 15px 0 !important;
    border: none;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
  }
  &.list-selector-modal-collapsed {
    .modal-content {
      padding: 0 !important;
    }
  }

  .modal-content {
    border: none;
    background: transparent;
  }

  .react-table {
    max-height: 70vh;
  }

  .MuiTable-root {
    width: 100%;
  }

  .add-new {
    position: absolute;
    top: -30px;
    right: 35px;
  }

  .actions {
    text-align: right;
  }
}

.form-modal {
  .modal-body {
    padding: 0 !important;
    border: none;
    border-radius: 10px;
    background: #fff;
    max-height: calc(100vh - 120px);
    overflow: auto;
  }

  .modal-content {
    border: none;
    background: transparent;
  }
}

//# MUI
.MuiTooltip-popper {
  z-index: 3000 !important;
}

.MuiTooltip-tooltip {
  max-width: 50vw !important;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 14px;

  &.Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    background-color: #eff2f7;
    opacity: 1;
    color: #495057;
  }
}

.react-select-label {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0 8px !important;
  background: #fff;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute !important;
  left: -4px !important;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-table {
  max-height: calc(100dvh - 250px);

  //nested tables
  .react-table {
    max-height: initial;
  }

  .ctm-select {
    height: 30px !important;
    padding: 2px 0 0 2px;

    .css-pnkree-Control {
      height: 100% !important;
    }
  }

  thead {
    background: #eff2f7;
    border: 1px solid #eff2f7;

    tr {
      background: #eff2f7;
    }

    .sticky-header-column {
      z-index: 999;
      background: #eff2f7;
    }
  }

  tbody {
    > .MuiTableRow-root {
      background: #fff !important;
    }

    > .MuiTableRow-root:nth-child(odd) {
      background: rgb(239, 242, 247) !important;
    }

    > .MuiTableRow-root:hover {
      background: rgb(229, 232, 237) !important;
    }
  }
}

@media (max-width: 790px) {
  .custom-type-filter {
    max-width: 100% !important;
    margin: 6px 0 !important;
  }
}

.modal-backdrop.show {
  opacity: 0.35;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #868e96;
  }
}

::-webkit-scrollbar-track {
  background-color: #e9ecef;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background-color: transparent; /* For when both vertical and horizontal bars exist */
}

.api-form {
  position: relative;
  max-height: calc(100vh - (#{$header-height} + $footer-height + #{$grid-gutter-width} + #{$grid-gutter-width}));
  overflow: auto;
  overflow-x: hidden;



  .form-tabs,
  .configuration-tabs {
    position: sticky;
    top: 0px;
    z-index: 500;
  }

  textarea {
    resize: none;
  }

  .accordion-header.cf-tab,
  .accordion-header.cf-section {
    position: sticky;
    top: 36px;
    z-index: 450;

    .accordion-button {
      padding: 12px;
      color: #303030;
      font-size: 1rem;
    }
  }

  .accordion-header.cf-section {
    top: 54px;
  }
}

.form-sticky-header {
  background: #2a3042;
  margin: 0 0 6px 0 !important;
  padding: 12px;
  border-radius: 5px;

  h4 {
    color: #fff;
  }
}

.form-modal {
  .api-form {
    max-height: calc(100vh - 233px);

    .form-sticky-header {
      position: sticky;
      top: -0px;
      z-index: 500;
      padding: 10px;
      box-shadow: 0px 9px 0 -8px #ced4da;
      margin: 0 !important;
      min-height: 102px;
    }

    .form-tabs,
    .configuration-tabs {
      position: sticky;
      top: 110px;
      z-index: 450;
    }

    .accordion-header.cf-section,
    .accordion-header.cf-tab {
      z-index: 420;
    }

    .accordion-header.cf-tab {
      top: 143px;
    }

    .accordion-header.cf-section {
      top: 161px;
    }

    &.short-header {
      .form-tabs,
      .configuration-tabs {
        top: 104px;
      }

      .accordion-header.cf-tab {
        top: 141px;
      }

      .accordion-header.cf-section {
        top: 162px;
      }
    }
  }
}

.module-configuration-row {
  .save-action {
    position: sticky;
    bottom: 0;
    padding: 12px;
    background: #fff;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    z-index: 450;
  }
}

.module-filter-wrapper {
  position: relative;

  .module-filter-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    color: $primary;
    z-index: 430;
    background: #fff;
  }
}

.modal-header-nav-bar {
  width: 100%;
  background: #556ee6;
  color: #e9ecef;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 4px 12px;
  border: 1px solid #b9b9b9;
  border-radius: 10px 10px 0 0;

  > * {
    flex: 1 0 33%;
  }

  > *:first-child {
    text-align: left;
  }

  > *:last-child {
    text-align: right;
  }

  .modal-header-close {
    cursor: pointer;
  }

  .modal-header-title {
    font-size: 1.15em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.technology-file-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .technology-file-wrapper {
    margin: 12px;
    width: 320px;
  }

  .file-container {
    position: relative;
    border: 1px solid #dedede;
    border-radius: 10px;
    margin-bottom: 12px;
    padding: 12px 6px 12px 0;
    overflow: hidden;

    .remove-file-dot {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: #f26969;
      cursor: pointer;
      padding: 0 7px 4px 10px;
      border-radius: 0 0 0 100px;
      z-index: 410;
    }
  }
}

.task-modal {
  .modal-content {
    background: transparent;
    border: none;

    .modal-body {
      *:not(.ck) {
        color: #c5cfd9;
      }

      padding: 0;
      background: #2a3042;
      border-radius: 10px;

      .task-container {
        position: relative;
        max-height: 85vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0.5em;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          padding: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: lighten(#2a3042, 30);
          outline: 1px solid lighten(#2a3042, 25);
          border-radius: 10px;
        }

        .task-side-column {
          position: sticky;
          top: 0;
        }
      }

      .ck-toolbar__items,
      .ck-toolbar {
        background-color: darken(#2a3042, 10) !important;
        border: none !important;
      }

      .ck-editor__main {
        max-height: calc(100vh - 400px);
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0.5em;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          padding: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: lighten(#2a3042, 30);
          outline: 1px solid lighten(#2a3042, 25);
          border-radius: 10px;
        }

        .ck-editor__editable {
          background-color: #2a3042 !important;
          background: #2a3042;
        }

        .ck-content {
          border: none;
        }
      }

      .cke-wrapper {
        &.disabled {
          .ck-editor__top {
            display: none;
          }
        }
      }

      .comments {
        .ck-editor__editable {
          min-height: 100px !important;
        }
      }
    }
  }
}

.amount-change-modal {
  .change-input {
    .MuiInputBase-input {
      text-align: center;
      font-size: 2.2em;
      font-weight: 500;
    }
  }

  button:not(.close-btn) {
    font-size: 4em;
    width: 100%;
    color: #fff !important;
  }
}

.table-selectable-actions {
  z-index: 300 !important;
}

.merge-tasks-modal {
  .modal-content {
    background: transparent;
    border: none;

    .modal-body {
      *:not(.ck) {
        color: #c5cfd9;
      }

      padding: 0;
      background: #2a3042;
      border-radius: 10px;
    }
  }
}

.custom-field-section-collapse {
  .accordion-body {
    padding: 0.25rem;
  }
}

.dark-scroll {
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    padding: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten(#2a3042, 30);
    outline: 1px solid lighten(#2a3042, 25);
    border-radius: 10px;
  }
}

.template-tabs {
  background: #2a3042;
  position: sticky;
  top: 0;
  z-index: 420;

  &.MuiTabs-root {
    min-height: 30px;
  }

  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      color: #fff;
      font-size: 0.8rem;
      padding: 10px;
      min-height: 30px;
      text-transform: capitalize;

      &.Mui-selected {
        background: #192031;
      }
    }
  }
}

.layout-config {
  padding-bottom: 80px;
}

.transparent-input {
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
}

.field-config-wrapper {
  border: 1px solid #dedede;
  overflow: hidden;

  .field-config {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #f9f9f9;

    .field-name-grid-input {
      width: 100%;
    }

    .move-field {
      padding: 12px;
      text-align: center;
      cursor: move;
      width: 60px;
    }

    .input-wrapper {
      width: calc(100% - 80px - 60px);
      position: relative;

      .badge-container {
        position: absolute;
        width: 100%;
        text-align: left;
        bottom: -9px;
        left: 12px;
        height: 20px;
        overflow-y: hidden;

        .badge {
          padding: 4px;
          font-size: 0.6rem;
          margin-right: 6px;

          &:last-child {
            margin-right: initial;
          }
        }
      }
    }

    .buttons-container {
      padding-right: 12px;
      width: 80px;
      display: flex;
      justify-content: space-evenly;
    }
  }
}

.cf-text-editor {
  position: relative;
  width: 100%;
  max-height: 100%;
  padding-right: 10px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;

  .form-label {
    position: sticky;
    top: 0px;
    z-index: 450;
    background: #fff;
    width: 100%;
  }

  .ck.ck-editor__top {
    position: sticky;
    top: 18px;
    z-index: 410;
  }
}

.record-reader {
  position: relative;

  .record-reader-actions {
    display: flex;
    justify-content: end;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    right: 0;
    height: 100%;
    width: 0;
    text-align: right;
    transition: all 0.2s ease-in;
    z-index: 99;

    &.ctm-relation-single-picker {
      bottom: 0;
      top: initial;
      transform: initial;
      right: 0;
      height: 36px;
    }

    .MuiButtonBase-root {
      min-width: 30px;
    }
  }

  &:hover {
    .record-reader-actions {
      width: 85px;
      max-width: 85px;
    }
  }
}

.react-table {
  .record-reader {
    > span,
    > div {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
    }
  }
}

.module-filter-wrapper {
  .record-reader-actions {
    display: none;
  }
}

.btn.btn-accent {
  $btnColor: #f44336;
  background: $btnColor;
  color: #fff;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background: darken($btnColor, 10);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.table-picker {
  .MuiTableCell-body {
    padding: 8px;

    &.picker {
      width: 70px;
    }
  }
}

.Mui-disabled {
  background: #eff2f7;
}

.input--more-actions {
  position: relative;
  height: 24px;
  .btn-expander {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    background: transparent;
    border-color: #dedede;
    i {
      padding: 0 4px;
      color: rgb(73, 80, 87);
    }
  }
  .input--more-buttons {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    width: 0px;
    overflow: hidden;
    background: #fff;
  }
  .btn-expander:hover {
    z-index: 1;
  }
  .btn-expander:hover + .input--more-buttons {
    opacity: 1;
    width: initial;
    z-index: 3;
  }

  .input--more-buttons:hover {
    opacity: 1;
    width: initial;
    z-index: 3;
  }
}

.modal-datagrid-wrapper {
  transition: all 0.8s ease-in;
  &.collapsed {
    max-height: 0;
    overflow: hidden;
  }
}

.modal {
  &.collapsed {
    width: 0px;
    height: 0px;
  }
}

.lightbox-wrapper {
  img {
    display: block;
    margin: 0 auto;
  }
  .packing-instruction {
    img {
      width: 500px;
      height: 500px;
    }
  }
  position: relative;
  &:hover {
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
    &:after {
      font-family: 'boxicons' !important;
      content: '\ea87';
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      color: #fff;
      font-size: 2rem;
    }
  }
}

.product-builder-graph {
  .context-menu {
    background: white;
    border-style: solid;
    box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
    position: fixed;
    z-index: 10;
    button {
      border: none;
      display: block;
      padding: 0.5em;
      text-align: left;
      width: 100%;
      &:hover {
        background: white;
      }
    }
  }
  .react-flow__node-productNode {
    background: transparent !important;
    &.selected {
      border: 2px dashed #ccc;
    }
    .side-action {
      position: absolute;
      z-index: 100;
      left: 100%;
      background: #556ee6;
      color: #fff;
      transition: all 0.3s;
      width: 0;
      overflow: hidden;
    }
    .side-action-container {
      position: absolute;
      z-index: 100;
      left: 100%;
      background: transparent;
      color: #fff;
      transition: all 0.3s;
      width: 0;
      height: 100%;
      overflow: hidden;
      .side-action-actions {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        margin: 0 auto;
        width: 29px;
        padding: 6px 0;
        height: 100%;
        justify-content: space-evenly;
        background: #f2f2f5;
        border: 2px solid #ff9500;
      }
    }
    &:hover {
      .side-action {
        width: 26px;
      }
      .side-action-container {
        width: 40px;
      }
    }
    .node-product-type {
      border-radius: 4px;
      margin-bottom: -5px;

      .MuiInputBase-root {
        padding-left: 30px;
        i {
          font-size: 20px;
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translateY(-50%);
          padding: 3px 8px;
        }
      }
      .MuiInputBase-input {
        text-align: center;
      }
    }
    .labeler-id {
      background: #fff;
      padding: 0 4px;
      border-radius: 20px;
    }
    .product-flow-node {
      background: #fff;
      padding: 10px;
      border-radius: 5px;
      height: calc(100% - 34px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.mode-technology {
        padding: 0;

        .MuiTabs-root {
          min-height: 25px;

          .MuiButtonBase-root {
            font-size: 10px;
            min-height: 25px;
          }
        }
        .files-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding: 10px;
          color: #303030;
          overflow-y: auto;
          overflow-x: hidden;
          flex-wrap: wrap;
          text-align: center;

          button {
            padding: 2px;
            min-width: initial;
          }
        }
      }

      .MuiFormLabel-root,
      .MuiTypography-root {
        font-size: 0.75rem;
      }
      .MuiTextField-root {
        border-radius: 4px;
        .MuiInputBase-input {
          font-size: 10px !important;
        }
      }
    }
  }
}

.config-panel {
  background: rgba(255, 255, 255, 0.9);
  padding: 6px;
  border: #303030 dashed 1px;
  border-radius: 5px;
  position: relative;
  overflow-x: hidden;
  transition: all 0.3s;
  left: 450px;
  max-width: 400px;
  &__toggler {
    transition: all 0.3s;
    position: absolute;
    left: 390px;
    top: 0;
    height: 100%;
    width: 26px;
    background: #dedede;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    border: #303030 dashed 1px;
    border-right: none;
    &.active {
      left: -24px;
    }
  }
  &__content {
    position: relative;
    overflow-x: hidden;
    transition: all 0.3s;
  }
  &.active {
    left: 0px;
  }
}

.blocks-to-duplicate {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      justify-content: space-between;
      padding: 6px;
      border-bottom: 1px solid #dedede;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.app-search {
  .select2-selection__control {
    border-radius: 20px;
    background-color: #f3f3f9 !important;

    transition: all 0.3s ease-in;

    &.select2-selection__control--is-focused {
     width:320px;
    }
  }

  .search-result {
    .search-result-record {
    }

    .search-result-module {
      text-align: right;
      font-size: 13px;
      font-weight: bold;
      color: #556ee6;
    }
  }
}

.export-form__table {
  .export-form__row {
    .indexer__col {
      .indexer__col--number {
        display: block;
      }

      .indexer__col--icon {
        cursor: pointer;
        display: none;
      }
    }

    &:hover {
      .indexer__col {
        .indexer__col--number {
          display: none;
        }

        .indexer__col--icon {
          display: block;
        }
      }
    }
  }
}

.MuiFormControl-root .Mui-error {
  margin: 2px 0 0 0;
}

.custom-section {
  padding: 18px 6px;
}

.form-accountancy-invoice {
  .labeler-id {
    display: none;
  }
}

td > .ctm-custom-field, th > .ctm-custom-field {
  padding: 0 !important;
  margin: 0 auto !important;
  > div {
    padding: 0 !important;
    margin: 0 auto !important;
    > .row, .mb-3 {
      padding: 0 !important;
      margin: 0 auto !important;
      > .row, .mb-3 {
        padding: 0 !important;
        margin: 0 auto !important;
      }
    }
  }
}
