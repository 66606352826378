.filter {
  &__clear-button {
    display: none !important;
    top: -6px;
    right: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    background-color: #f00;
    border-radius: 100%;
    position: absolute;
    z-index: 9;

    .lucide {
      height: 14px !important;
      width: 14px !important;
      color: #fff;
      margin: 0px !important;
    }

    &--show {
      display: flex !important;
    }
  }
}
